@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* NAVBAR */
.navbar {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0 8px 6px -6px gray;
  --webkit-box-shadow: 0 8px 6px -6px gray;
  border-bottom: solid gray !important;
}

.nav-primero::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none !important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/*  */
.nav__list{
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.nav__list a{
	display:block;
	color:#080707;
	text-decoration:none;
	font-weight:700;
	font-size:12px;
	line-height:32px;
	padding:0 15px;
}

.nav__list li{
	position: relative;
	float:  left;
	margin: 0;
	padding: 0;
}

.nav__list li:hover{
	background-color: rgb(188, 188, 188);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
  border-radius: 10px;
}

.nav__list ul{
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #ffffff;
	padding: 0;
	list-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
  border-radius: 10px;
}

.nav__list ul li{
	float: none;
	width: 200px;
}

.nav__list ul a{
	line-height: 120%;
	padding: 10px 15px;
}

.nav__list ul ul{
	position: absolute;
	top: 0;
	left: 50%;
	background-color: #fff;
}

.nav__list li:hover > ul{
	display: block;
}
/* .nav-link {
  font-weight: 300 !important;
} */

/* .active {
  border-bottom: 2px solid black;
} */
.noActive {
  border-bottom: none !important;
}


.navbar-brand {
  font-weight: 700;
  font-family: 'roboto', sans-serif;
  letter-spacing: 3px;
}

/* FOOTER */
.footer {
  color: white;
  padding: 25px;
  background: #000000;
  background: -webkit-linear-gradient(to right,
      #434343,
      #000000);
  background: linear-gradient(to right, #434343,
      #000000);
}
.footer a{
  text-decoration: none;
  color: white;
  padding: 10px; 
}
.footer a:hover{
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
}

/* Page Not Found */
.pnf{
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pnf-title{
  font-size: 100px;
  font-weight: 700;
}
.pnf-heading{
  font-weight: normal;

}
.pnf-btn{
  color: black;
  border: 1px solid black;
  padding: 10px;
  text-decoration: none;
  margin-top: 10px;
}
.pnf-btn:hover{
  background-color: black;
  color: white;
}

/* Contactus */
.contactus{
  margin: 0;
  padding: 0;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* REGISTER */
.register{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  flex-direction: column;
}

/* ================== */
.product-link{
  text-decoration: none !important;
  color: black !important;
}


body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
a, a:hover {
    text-decoration: none !important; }

.footer-99382 {
    padding: 2rem 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: #777;
    background-color: gray;
    position: relative;
    font-family: "Poppins", sans-serif; }
  .footer-99382 h3 {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 30px; }
  .footer-99382 .footer-site-logo {
      font-size: 1.5rem;
      color: #fff; }
  .footer-99382 .nav-links li {
      margin-bottom: 10px; }
    .footer-99382 .nav-links li a {
        color: #999999; }
      .footer-99382 .nav-links li a:hover {
          color: #fff; }
  .footer-99382 .btn.btn-tertiary {
      background-color: #e42c64;
      color: #fff;
      border-radius: 30px;
      border: none;
      padding: 10px 20px; }
  .footer-99382 .social li {
      display: inline-block; }
    .footer-99382 .social li a {
        color: gray;
        padding: 7px; }
      .footer-99382 .social li a:hover {
          color: #fff; }
  .footer-99382 .copyright {
      border-top: 1px solid #595959; }
.footer-99382:before {
        
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); }


  .product-grid {
    
    gap: 1rem;
  }
  
  @media (min-width: 600px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  
  @media (min-width: 900px) {
    .product-grid {
      grid-template-columns: repeat(3, 1fr);
    } 
  }
  
  @media (min-width: 1200px) {
    .product-grid {
      grid-template-columns: repeat(5, 1fr);
    }
  }


.parrafo-contenedor {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* Product details */
.imagenes{

    width: 100%;
    height: 300px;
    object-fit: contain;
    border-radius: inherit;
    box-shadow: 0 60px 40px rgb(0, 0, 0 / 8%);
}


.filtersMobil {
  /* padding-left: 40%; */
  /* text-align: center; */
  display: flex;
  /* justify-content: center; */
  overflow-x: auto;
  white-space: nowrap;
}
.filters {
  text-align: center;
  display: flex;
  /* justify-content: center; */
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
}

.product-tabs {
  margin-bottom: 20px;
}

.ordering select {
  margin: 0 auto;
}

.switcher {
  display: flex;
  justify-content: center; 
}

.switcher button {
  margin: 0 10px;
}
.btn-light {
  border: 1px solid #000;
}
    

.navbar {
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
}

#navbarToggleExternalContent3 {
  position: fixed;
  bottom: 56px; 
  left: 0;
  right: 0;
  background: white;
}

.imagenesHome {
  height: 172px;
}
/* vista movil */

/* .imagenes {
  height: 213px;
} */
.MDBCard {
  max-width: 300px;
}

.MDBCardTitle {
  font-size: 14px;
}

.MDBCardBody {
  padding: 10px;
}

.MDBBtn {
  font-size: 12px;
  padding: 5px 10px;
}

.tarjeta{
 box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),
  0 4px 6px -2px rgba(0,0,0,0.05);
 
border-radius: 4px;

transform: translateY(-10px);
}

.tarjeta:hover {
transform: translateY(-15px);  
}


.td_tabla {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.input-group {
  width: 100%;
  display: flex;
}

.form-control {
  flex: 1;
  text-align: center;
}

.input-group-btn {
  width: 40px;
}

.Btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(255, 65, 65);
}

/* plus sign */
.sign {
  width: 100%;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign svg {
  width: 17px;
}

.sign svg path {
  fill: white;
}
/* text */
.text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  transition-duration: .3s;
}
/* hover effect on button width */
.Btn:hover {
  width: 125px;
  border-radius: 40px;
  transition-duration: .3s;
}

.Btn:hover .sign {
  width: 30%;
  transition-duration: .3s;
  padding-left: 20px;
}
/* hover effect button's text */
.Btn:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: .3s;
  padding-right: 10px;
}
/* button click effect*/
.Btn:active {
  transform: translate(2px ,2px);
}

.card-cart {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
  height: 150px;
  display: flex;
  flex-direction: row; /* cambiar a row */
  border-radius: 5px;
}

.card-image-cart {
  margin-right: 10px;
  width: 30%; 
  
}

.card-content-cart {
  width: 70%;
}

/* @media (max-width: 1024px) {

  .card {
    flex-direction: row; 
  }

  .card-image,
  .card-content {
    width: 100%;
  }

} */

.img-cart-card {
  width: 100%;
  height: 100%;
  object-fit: contain !important; 
}